
import { useState, useCallback } from "react";

export const useApiGetData = (apiUrl, query) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const getAPIData = useCallback(async () => {
    setLoading(true);
    const graphqlQuery = {
      query: query(),
      variables: {},
    };

    try {
      const apiResponse = await fetch(`${apiUrl}`, {
        method: "POST",
        body: JSON.stringify(graphqlQuery),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await apiResponse.json();
      return res.data;
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [query, apiUrl]);

  return { loading, error, getAPIData };
};
