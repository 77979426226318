const getQuestionMap = () => `
query {
    NikeActivityFinderQuestionMap {
        id,
        name,
        Sequences {
            id,
            item {
                ... on NikeActivityFinderQuestions {
                    id,
                    name,
                    __typename
                }
                ... on NikeActivityFinderAnswers {
                    id,
                    name,
                    __typename
                }
            }
        }
    }
  }
`;

export default getQuestionMap;
