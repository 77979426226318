const getResults = () => `
query {
    NikeActivityFinderResults(limit: 5000) {
        id,
        name,
        tags,
        translations {
            id,
            NikeActivityFinderLanguages_code {
                code, 
                name,
            },
            title,
            deeplink
        }
    }
  }
`;

export default getResults;
