import { useState, useEffect, useMemo } from "react";
import { Dropdown, initializeIcons } from "@fluentui/react";

initializeIcons();

const dropdownStyles = { dropdown: { width: 300 } };

const QuestionsDropDown = ({ question, onSelected, isActive, highlight }) => {
  const [selectedItem, setSelectedItem] = useState();

  const options = useMemo(() => {
    const currAnswers = question.answers.map((a) => ({
      key: `A-${a.id}`,
      text: a.name,
      tags: a.tags,
      id: a.id,
    }));

    return [{ key: `Q-${question.id}`, text: question.name }, ...currAnswers];
  }, [question]);

  useEffect(() => {
    if (!isActive) {
      setSelectedItem(options[0]);
    }
  }, [isActive, options]);

  const onChange = (_, item) => {
    setSelectedItem(item);
    onSelected(item, parseInt(question.id));
  };

  const selectedKey = selectedItem?.key;

  console.log(highlight);

  return (
    <Dropdown
      style={{
        outline: highlight ? "4px solid green" : "none",
        backgroundColor: highlight ? "white" : "black",
      }}
      disabled={!isActive}
      placeholder={question.name}
      selectedKey={selectedKey}
      onChange={onChange}
      options={options}
      styles={dropdownStyles}
      notifyOnReselect={true}
    />
  );
};

export default QuestionsDropDown;
