import { useEffect, useState } from "react";
import {
  useApiGetData,
  questionsQuery,
  resultsQuery,
  questionMapQuery,
} from "./utils";
import QuestionsDropDown from "./components/dropDown";
import ResultsGrid from "./components/resultsGrid";
import Tag from "./components/tag";
import "./App.css";

const GetNextQuestionIndex = (map, answers) => {
  // 1. find closest map match that includes all questions and answers so far selected

  //2. find most recent answer node
  var answerIndex = map.Sequences.findIndex(
    (s) => s.item.name === answers[answers.length - 1].text
  );

  //3. find all remaining Questions and answers
  const remaining = map.Sequences.slice(answerIndex);

  //4. find next question
  var nextQuestion = remaining.find(
    (s) => s.item.__typename === "NikeActivityFinderQuestions"
  );

  const nextQuestionIndex = nextQuestion
    ? map.Sequences.findIndex(
        (f) =>
          f.item.__typename === nextQuestion.item.__typename &&
          f.item.id === nextQuestion.item.id
      )
    : -1;

  //5. return question Id or null
  return nextQuestionIndex > -1 ? nextQuestionIndex : null;
};

function App() {
  const languages = ["en", "fr", "de", "it", "es", "tu"];
  const [selectedLangIndex, setSelectedLangIndex] = useState(-1);
  const [questions, setQuestions] = useState([]);
  const [results, setResults] = useState([]);
  const [questionMaps, setQuestionMaps] = useState([]);
  const [activeQuestions, setActiveQuestions] = useState([1]);
  const [responses, setResponses] = useState([]); //{question, answer}

  const totalResultsNum = results.length;
  const selectedLang = languages[selectedLangIndex];

  const { getAPIData: getQuestions } = useApiGetData(
    `${process.env.REACT_APP_API_URL}/graphql`,
    questionsQuery
  );

  const { getAPIData: getResults } = useApiGetData(
    `${process.env.REACT_APP_API_URL}/graphql`,
    resultsQuery
  );

  const { getAPIData: getQuestionMap } = useApiGetData(
    `${process.env.REACT_APP_API_URL}/graphql`,
    questionMapQuery
  );

  useEffect(() => {
    async function getData() {
      var questionsData = await getQuestions();
      setQuestions(questionsData.NikeActivityFinderQuestions);
      var resultsData = await getResults();
      setResults(resultsData.NikeActivityFinderResults);
      var questionMapData = await getQuestionMap();
      setQuestionMaps(questionMapData.NikeActivityFinderQuestionMap);
    }

    getData();
  }, [getQuestions, getResults, getQuestionMap]);

  // Handle making next question active when responses change
  useEffect(() => {
    if (!questionMaps) return;
    if (responses.length === 0) return;

    // Find all answered questions and all answers
    var answeredQuestions = responses.map((r) => r.question);
    var answers = responses.map((a) => a.answer);

    // Get matching QA map
    var map = questionMaps.find(
      (q) =>
        answeredQuestions.every((aq) =>
          q.Sequences.find((s) => s.item.name === aq.name)
        ) &&
        answers.every((aq) => q.Sequences.find((v) => v.item.name === aq.text))
    );

    // Get Next Question
    const nextQuestionIndex = GetNextQuestionIndex(map, answers);
    if (nextQuestionIndex == null) return;

    const nextQuestion = map.Sequences[nextQuestionIndex].item;
    const nextQuestionId = parseInt(nextQuestion.id);

    // Set Next Active Questions (Handling previous responses being invalid)
    setActiveQuestions((activeQuestions) => {
      // Find questions answered from a different map or ahead of this question
      const invalidPrevQuestions = activeQuestions.filter((aq) => {
        const index = map.Sequences.findIndex(
          (s) =>
            s.item.__typename === "NikeActivityFinderQuestions" &&
            parseInt(s.item.id) === aq
        );
        return index === -1 || index > nextQuestionIndex;
      });

      const validNextQuestions = activeQuestions.filter(
        (aq) => !invalidPrevQuestions.includes(aq)
      );
      return validNextQuestions.includes(nextQuestionId)
        ? validNextQuestions
        : validNextQuestions.concat([nextQuestionId]);
    });
  }, [questionMaps, questions, responses]);

  const onAnswerSelected = (answer, questionId) => {
    var question = questions.find((q) => parseInt(q.id) === questionId);
    var indexAlreadyAnswered = responses.findIndex(
      (r) => parseInt(r.question.id) === questionId
    );
    if (indexAlreadyAnswered > -1) {
      // remove any responses that follow this one
      setResponses((responses) => [
        ...responses.slice(0, indexAlreadyAnswered),
        { question, answer },
      ]);
    } else {
      setResponses((responses) => responses.concat({ question, answer }));
    }
  };

  const tags = responses
    .map((a) => a.answer)
    .filter((s) => s.tags)
    .map((a) => a.tags)
    .flat(2);

  const validResults = results.filter(
    (r) =>
      (tags.length === 0 ||
        r.tags.filter((t) => tags.includes(t)).length === tags.length) &&
      (!selectedLang ||
        r.translations.findIndex(
          (t) =>
            t.NikeActivityFinderLanguages_code.code === selectedLang &&
            t.deeplink &&
            t.deeplink !== ""
        ) > -1)
  );

  const validResultsNum = validResults.length;

  const onLangSelect = ({ id }) => {
    setSelectedLangIndex(id);
  };

  return (
    <div className="App App-box">
      <div className="questionsWrapper">
        <div className="questions">
          <span>Languages</span>
          <QuestionsDropDown
            question={{
              id: 0,
              name: "Select Language",
              answers: languages.map((l, i) => ({ id: i, key: l, name: l })),
            }}
            onSelected={onLangSelect}
            isActive={true}
          />
        </div>
        <div className="questions">
          {questions
            .sort((a, b) => a.name > b.name)
            .map((q, i) => {
              return (
                <QuestionsDropDown
                  key={`q-${i}`}
                  question={q}
                  onSelected={onAnswerSelected}
                  isActive={activeQuestions.includes(parseInt(q.id))}
                  highlight={
                    parseInt(q.id) ===
                    activeQuestions[activeQuestions.length - 1]
                  }
                />
              );
            })}
        </div>
        <div className="tags">
          {tags.length > 0 && <div>Selected Tags:</div>}
          {tags.map((t, i) => (
            <Tag key={`tag-${i}`} text={t} />
          ))}
        </div>
        <div>
          Results Available: {validResultsNum} of {totalResultsNum}
        </div>
      </div>
      <ResultsGrid lang={selectedLang} results={validResults} />
    </div>
  );
}

export default App;
