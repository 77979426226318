const getQuestions = () => `
query {
    NikeActivityFinderQuestions {
        id,
        name,
        answers {
            id,
            name,
            tags
        }
    }
  }
`;

export default getQuestions;
