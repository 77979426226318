import { useState, useEffect } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import Tag from "./tag";

const classNames = mergeStyleSets({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  itemCell: {
    minHeight: 54,
    width: 200,
    padding: 10,
    boxSizing: "border-box",
    border: `1px solid white`,
    marginBottom: "5px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
  },
  itemName: {
    fontSize: "15px",
  },
  itemLabel: {
    fontSize: "12px",
  },
  link: {
    color: "white",
  },
});

export const ListBasicExample = ({ results, lang }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const keyItems = results.map((r, i) => ({ key: `item-${i}`, ...r }));
    setItems(keyItems);
  }, [results]);

  return (
    <div className={classNames.wrapper}>
      {items.map((item, i) => {
        const translation = item.translations.find(
          (t) => !lang || t.NikeActivityFinderLanguages_code.code === lang
        );
        return (
          <div className={classNames.itemCell} key={`item-${i}`}>
            <div className={classNames.itemName}>
              <span className={classNames.itemLabel}>Name: </span>
              {item.name}
            </div>
            {item.tags.map((t, i) => (
              <Tag
                color={
                  t === "run"
                    ? "blue"
                    : t === "training"
                    ? "black"
                    : t === "yoga"
                    ? "teal"
                    : t === "dance"
                    ? "red"
                    : t === "stretch"
                    ? "darkorange"
                    : "grey"
                }
                key={`tag-${i}`}
                text={t}
              />
            ))}
            <a className={classNames.link} href={translation?.deeplink}>
              SmartLink
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default ListBasicExample;
